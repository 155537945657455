<!-- eslint-disable ts/ban-ts-comment -->
<script setup lang="ts">
import type { HomePageResponse } from "~/types/rest_api_types/pages/home";
import type { SEOResponseType } from "~/types/rest_api_types/seo";

const { locale, localeProperties, t } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();
const slug = getLocalizedRoute(locale.value, "index").backendSlug || "";

const { data, error } = await useAsyncData(
  "homepage",
  async () => {
    const homeRequest = await $fetch<HomePageResponse>(`/api/pages/home?slug=${slug}`, {
      method: "GET",
    });

    const seoRequest = await $fetch<SEOResponseType>(`/api/seo?slug=${slug}`, {
      method: "GET",
    });

    const [homeData, seoData] = await Promise.all([homeRequest, seoRequest]);

    return {
      content: {
        hero: {
          title: homeData?.hero.title,
          description: homeData?.hero.description,
          bulletPoints: parseBulletPoints(homeData?.hero.bulletPoints ?? ""),
          image: {
            src: homeData?.hero.image.url,
            alt: homeData?.hero.image.altText,
          },
          ctaLabel: homeData?.hero.ctaButtonLabel,
        },
        featuredOn: {
          title: homeData?.featured_on?.title ?? "",
          images: homeData?.featured_on?.images ?? [],
        },
        visas: {
          title: t("visasAndGreenCards"),
          items: [
            ...(homeData?.visa?.categories?.map(edge => ({
              title: edge?.services?.[0]?.title ?? "",
              subtitle: edge?.services?.[0]?.subtitle ?? "",
              excerpt: edge?.services?.[0]?.excerpt ?? "",
              image: {
                url: edge?.services?.[0]?.image?.sourceUrl,
                alt: edge.services?.[0]?.image?.altText,
              },
              to: `${getLocalizedRoute(locale.value, "visas").path}/${edge.services?.[0]?.slug}`,
            })) ?? []),
            ...(homeData?.greenCards?.categories?.map(edge => ({
              title: edge?.services?.[0]?.title,
              subtitle: edge?.services?.[0]?.subtitle,
              excerpt: edge?.services?.[0]?.excerpt,
              image: {
                url: edge?.services?.[0]?.image?.sourceUrl,
                alt: edge.services?.[0]?.image?.altText,
              },
              to: `${getLocalizedRoute(locale.value, "greenCard").path}/${edge.services?.[0]?.slug}`,
            })) ?? []),
          ].filter(item => item.title),
          seeAllLabel: homeData?.visa?.button,
        },
        applicationProcess: {
          title: homeData?.applicationProcess?.title,
          list: Object.entries(homeData?.applicationProcess?.steps || {})
            .map(([_, process]: [string, any]) => ({
              title: process?.title,
              subtitle: process?.subtitle,
              description: process?.description,
              image: {
                url: process?.image ?? "/",
                alt: process?.title,
              },
            })),
        },
        testimonials: {
          title: homeData?.testimonials?.title,
          list: homeData?.testimonials?.items.map(edge => ({
            avatar: {
              url: edge?.image,
              alt: `${edge?.firstname} ${edge?.lastname}`,
            },
            firstname: edge?.firstname ?? "",
            lastname: edge?.lastname ?? "",
            company: edge?.company ?? "",
            title: edge?.title ?? "",
            review: edge?.review ?? "",
            to: "#",

          })),
        },
        blog: {
          title: homeData?.blog?.title,
          list: homeData?.blog?.posts.map(node => ({
            image: node.featuredImage?.node?.sourceUrl
              ? {
                  url: node.featuredImage?.node?.sourceUrl,
                  alt: node.featuredImage?.node?.altText ?? "",
                }
              : {
                  url: "http://cms.grapelaw.com/wp-content/uploads/2024/04/blog_placeholder.jpg",
                  alt: "",
                },
            title: node.title || "",
            category: node.categories?.edges[0]?.node || "",
            date: node.date ? formatDate(node.date, localeProperties.value.iso) : formatDate(new Date().toISOString(), localeProperties.value.iso),
            to: `${getLocalizedRoute(locale.value, "blog").path}/${node.slug}`,
          })).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
        },
      },
      seo: mapSeo(seoData),
    };
  },
);

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.statusMessage,
    fatal: true,
  });
}

const { schemaWebPage } = useSeo(data.value!.seo);

useHead({
  titleTemplate: "%s",
});

useSchemaOrg([
  defineWebPage({
    ...schemaWebPage,
  }),
]);
</script>

<template>
  <div class="space-y-24 pb-24">
    <!-- Hero -->
    <Hero
      :title="data?.content?.hero?.title"
      :description="data?.content?.hero?.description"
      :image="{
        src: data?.content?.hero?.image?.src,
        alt: data?.content?.hero?.image?.alt,
        width: '565px',
        height: '591px',
      }"
      :list="data?.content?.hero?.bulletPoints"
      :cta="{
        label: data?.content?.hero?.ctaLabel,
      }"
      :featured-on="{
        title: data?.content?.featuredOn?.title ?? '',
        images: data?.content?.featuredOn?.images ?? [],
      }"
    />
    <UContainer class="space-y-24">
      <div class="space-y-12">
        <!-- Visas & Green Cards -->
        <div v-motion-fade-visible-once>
          <SectionTitle
            :title="data?.content?.visas?.title"
            :link="{
              text: data?.content?.visas?.seeAllLabel ?? $t('seeAll'),
              href: `${getLocalizedRoute(locale, 'visas').path}`,
            }"
          />
          <CardVisa
            :visas="data?.content?.visas?.items.map(item => ({
              title: item.title ?? '',
              subtitle: item.subtitle ?? '',
              excerpt: item.excerpt ?? '',
              image: {
                url: item.image?.url ?? '',
                alt: item.image?.alt ?? '',
              },
              to: item.to,
            })) ?? []"
            overlay-hover
          />
        </div>
      </div>

      <NuxtLazyHydrate when-visible>
        <!-- Application Process -->
        <div
          v-motion-fade-visible-once
        >
          <SectionTitle
            :title="data?.content?.applicationProcess?.title"
            center-title-in-mobile
          />

          <div class="gap-16 xl:gap-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 mx-auto w-full">
            <div
              v-for="(process, index) in data?.content?.applicationProcess?.list ?? []"
              :key="index"
              class="flex justify-center"
            >
              <div class="flex flex-col items-center md:items-start w-full">
                <div class="h-14 shrink-0">
                  <NuxtPicture
                    :src="process.image.url"
                    alt=""
                    loading="lazy"
                    sizes="(max-width: 640px) 200px, (max-width: 768px) 200px, (max-width: 1024px) 200px, (max-width: 1280px) 200px, (max-width: 1536px) 200px, 200px"
                    provider="cloudflare"
                  />
                </div>
                <p class="pt-6 text-sm">
                  {{ process.title }}
                </p>
                <p class="pt-1 font-bold text-text-title text-xl">
                  {{ process.subtitle }}
                </p>
                <p class="pt-3 w-full font-medium text-gray-600 text-sm md:text-left text-center leading-relaxed">
                  {{ process.description }}
                </p>
              </div>

              <div
                v-if="Number(index) !== 3"
                class="hidden xl:block relative px-12 w-fit h-full"
              >
                <div class="bg-gradient-to-t from-transparent via-snow-200 to-transparent w-[2px] h-full" />
                <div class="absolute inset-y-0 flex items-center -translate-x-1/2">
                  <UButton
                    color="white"
                    :aria-label="t('nextStepButtonLabel')"
                    class="hover:bg-white rounded-full ring-text-title/10 hover:cursor-default"
                    size="lg"
                  >
                    <template #trailing>
                      <IconChevronRight class="size-5" />
                    </template>
                  </UButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Testimonials -->
        <div v-motion-fade-visible-once>
          <SectionTitle
            :title="data?.content?.testimonials?.title"
            :link="{
              text: $t('seeAll'),
              href: `${getLocalizedRoute(locale, 'testimonial').path}`,
            }"
          />

          <UCarousel
            v-slot="{ item }"
            :items="data?.content?.testimonials?.list ?? []"
            :ui="{
              container: 'gap-4',
              item: `basis-full md:basis-[calc((100%-1rem)/2)] lg:basis-[calc((100%-2rem)/3)] snap-start`,
            }"
            :prev-button="{
              color: 'white',
              icon: 'i-heroicons-chevron-left-20-solid',
              class: '-ml-7 shadow-lg text-text-paragraph',
            }"
            :next-button="{
              color: 'white',
              icon: 'i-heroicons-chevron-right-20-solid',
              class: '-mr-7 shadow-lg text-text-paragraph',
            }"
            arrows
          >
            <LazyCardTestimonial :testimonials="[item]" />
          </UCarousel>
        </div>

        <!-- Blog -->
        <div
          v-motion-fade-visible-once
        >
          <SectionTitle
            :title="data?.content?.blog?.title"
            :link="{
              text: $t('seeAll'),
              href: `${getLocalizedRoute(locale, 'blog').path}`,
            }"
          />

          <!-- If item size is less than 2, indicators cause invalid array length error -->
          <UCarousel
            v-slot="{ item }"
            :items="data?.content?.blog?.list ?? []"
            :ui="{
              container: 'gap-4 pt-1 pb-10',
              item: 'w-full md:basis-1/2 lg:basis-[calc((100%-2rem)/3)] xl:basis-[calc((100%-3rem)/4)] snap-start',
              indicators: {
                wrapper: 'static',
                active: 'bg-text-title',
                inactive: 'bg-snow-200/70 mix-blend-normal',
              },
            }"
            :indicators="data?.content?.blog?.list && data?.content?.blog?.list?.length > 1"
          >
            <LazyCardBlogItemCarousel
              :posts="[item]"
            />
          </UCarousel>
        </div>
      </NuxtLazyHydrate>
    </UContainer>

    <div class="space-y-24">
      <NuxtLazyHydrate when-visible>
        <!-- Contact Form -->
        <UContainer
          v-motion-fade-visible-once
        >
          <LazyFormContact
            mode="horizontal"
            narrow
          />
        </UContainer>
      </NuxtLazyHydrate>
    </div>
  </div>
</template>
